import type { AppProps } from 'next/app';

import { RouteHandlerReturn } from 'src/types/model';

import '../styles/global/global.scss';

function App({ Component, pageProps }: AppProps<RouteHandlerReturn>): JSX.Element {
  return <Component {...pageProps} />;
}

export default App;
